<template>
  <div>
    <div v-if="activeUserTeam.id">
      <div v-if="activeUserTeam.checklistConfirmedAt">
        <b-button
          v-if="status === true"
          size="sm"
          variant="success"
          pill
          :disabled="isTeamWorkingOnOrder"
          @click="toggleStatus"
        >
          {{ $t('team.status-switcher.active') }}
        </b-button>
        <b-button
          v-if="status === false"
          size="sm"
          variant="danger"
          pill
          :disabled="isTeamWorkingOnOrder"
          @click="toggleStatus"
        >
          {{ $t('team.status-switcher.inactive') }}
        </b-button>
      </div>
      <div v-else>
        <b-button
          variant="flat-warning"
          :to="{ name: 'team-checklist' }"
        >
          <feather-icon
            icon="CheckSquareIcon"
            size="16"
          />
          <span class="d-none d-md-inline-block ml-50">{{ $t('team.status-switcher.check-checklist') }}</span>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'

export default {
  components: { BButton },
  data() {
    return {
      status: null,
      disabled: false,
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.getters['auth/getUser']
    },
    activeUserTeam() {
      return this.$store.getters['auth/getUserTeam']
    },
    activeUserTeamStatus() {
      return this.$store.getters['auth/getUserTeam'].status
    },
    isTeamWorkingOnOrder() {
      if (!this.$store.getters['auth/getUserTeam'].currentOrder) {
        return false
      }

      const orderStatusesWhenTeamWorkingOnOrder = [
        'received',
        'confirmed',
        'on_way_to_pickup_point',
        'at_pickup_point',
        'patient_pick_up',
        'on_way_to_delivery_point',
        'at_delivery_point',
        'patient_delivery',
        'patient_not_taken',
      ]

      return orderStatusesWhenTeamWorkingOnOrder.includes(this.$store.getters['auth/getUserTeam'].currentOrder.status)
    },
    getCssClass() {
      return this.disabled || this.isTeamWorkingOnOrder ? 'opacity-50' : 'cursor-pointer'
    },
  },
  watch: {
    activeUserTeamStatus(status) {
      this.status = status
    },
  },
  mounted() {
    setTimeout(() => {
      this.status = this.activeUserTeamStatus
    }, 500)
  },
  methods: {
    toggleStatus() {
      if (this.disabled || this.isTeamWorkingOnOrder) {
        return
      }
      this.status = !this.status
      this.disabled = true
      this.$nextTick(() => {
        this.$store.dispatch('auth/updateUserTeamStatus', { status: this.status, teamId: this.activeUserTeam.id })
          .then(() => {
            window.toast.notify.success(this.$t('team.status-switcher.team-status-changed'))
            this.disabled = false
          })
      })
    },
  },
}
</script>
