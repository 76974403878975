<template>
  <div>
    <b-modal
      id="modal-password-change"
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      hide-footer
      :title="$t('popups.user.change-password.title')"
    >

      <validation-observer ref="passwordChangeValidation">
        <form
          id="passwordChangeForm"
          @submit.prevent
        >

          <validation-provider
            #default="{ errors }"
            :name="$t('conf.user.popup.password_input')"
            rules="required|min:6|max:40"
          >
            <label for="basic-password">
              {{ $t('conf.user.popup.password_input') }}
            </label>
            <b-input-group>
              <b-input-group-prepend>
                <b-button
                  variant="outline-primary"
                  :disabled="newPassword.length > 0"
                  @click="generatePassword"
                >
                  <feather-icon :icon="newPassword.length > 0 ? 'LockIcon' : 'UnlockIcon'" />
                </b-button>
              </b-input-group-prepend>
              <b-form-input
                id="basic-password"
                v-model="newPassword"
                :type="visiblePass ? 'text' : 'password'"
                minlength="6"
                maxlength="40"
                :placeholder="$t('conf.user.popup.password_input')"
                autocomplete="new-password"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-primary"
                  @click="passwordVisibility"
                >
                  <feather-icon :icon="visiblePass ? 'EyeOffIcon' : 'EyeIcon'" />
                </b-button>
              </b-input-group-append>
            </b-input-group>

            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>

          <div class="text-right my-1">
            <b-button
              class="btn-block"
              variant="primary"
              @click.prevent="submitForm"
            >
              {{ $t('popups.user.change-password.change-password-button') }}
            </b-button>
          </div>
        </form>
      </validation-observer>

    </b-modal>
  </div>
</template>

<script>
import { randomPassword, cloneNested, parseRequestError } from '@/helpers/helpers'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  BButton, BFormInput, BInputGroup, BInputGroupAppend, BInputGroupPrepend,
} from 'bootstrap-vue'
import { required, min, max } from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BButton,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
  },
  data() {
    return {
      visiblePass: false,
      newPassword: '',
      required,
      min,
      max,
    }
  },
  computed: {
    me() {
      return cloneNested(this.$store.getters['auth/getUser'])
    },
    requirePasswordChange() {
      return this.me.requirePasswordChange
    },
  },
  mounted() {
    if (this.requirePasswordChange) {
      this.$bvModal.show('modal-password-change')
    }
  },
  methods: {
    submitForm() {
      this.$refs.passwordChangeValidation.validate()
        .then(valid => {
          if (!valid) {
            return false
          }
          this.$store.dispatch('user/changeMyPassword', { password: this.newPassword })
            .then(() => {
              this.me.requirePasswordChange = false
              this.$store.dispatch('auth/setUserInfo', this.me)
              this.newPassword = ''
              window.toast.notify.success(this.$t('popups.user.change-password.success'))
              this.$bvModal.hide('modal-password-change')
            })
            .catch(err => parseRequestError(err))

          return true
        })
    },
    passwordVisibility() {
      this.visiblePass = !this.visiblePass
    },
    generatePassword() {
      this.newPassword = randomPassword()
      this.visiblePass = true
    },
  },
}
</script>
