var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"modal-team-init","no-close-on-backdrop":"","no-close-on-esc":"","hide-header-close":"","title":_vm.$t('popups.user.init-team.title')},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"w-100 text-right"},[(_vm.forceShow)?_c('b-button',{attrs:{"disabled":_vm.loading,"variant":"flat"},on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.$t('shared.cancel'))+" ")]):_vm._e(),(!_vm.forceShow)?_c('b-button',{attrs:{"disabled":_vm.loading,"variant":"flat"},on:{"click":_vm.logout}},[_vm._v(" "+_vm._s(_vm.$t('shared.logout'))+" ")]):_vm._e(),_c('b-button',{attrs:{"variant":"primary","disabled":_vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.submitForm($event)}}},[_vm._v(" "+_vm._s(_vm.$t('popups.user.init-team.button'))+" ")])],1)]},proxy:true}])},[_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col w-full"},[_c('validation-observer',{ref:"initTeamValidation"},[_c('form',{attrs:{"id":"initTeamForm"},on:{"submit":function($event){$event.preventDefault();}}},[_c('div',[_c('validation-provider',{attrs:{"name":_vm.$t('popups.user.init-team.team-name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"team-name"}},[_vm._v(" "+_vm._s(_vm.$t('popups.user.init-team.team-name'))+" ")]),_c('b-form-group',[_c('v-select',{attrs:{"id":"team-name","placeholder":_vm.$t('popups.user.init-team.select-team-name'),"options":_vm.teamNames,"disabled":_vm.loading,"clearable":false,"searchable":false,"label":"name","reduce":function (name) { return name.id; }},model:{value:(_vm.myTeam.teamName),callback:function ($$v) {_vm.$set(_vm.myTeam, "teamName", $$v)},expression:"myTeam.teamName"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(_vm._s(_vm.$t('popups.user.init-team.select-team-name-no-result')))])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('div',[_c('validation-provider',{attrs:{"name":_vm.$t('popups.user.init-team.vehicle'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"vehicle"}},[_vm._v(" "+_vm._s(_vm.$t('popups.user.init-team.vehicle'))+" ")]),_c('b-form-group',[_c('v-select',{attrs:{"id":"vehicle","placeholder":_vm.$t('popups.user.init-team.select-vehicle'),"options":_vm.vehicles,"disabled":_vm.loading,"clearable":false,"searchable":false,"reduce":function (name) { return name.id; }},scopedSlots:_vm._u([{key:"selected-option",fn:function(option){return _c('span',{},[_vm._v(" "+_vm._s(option.sideNumber)+" ("+_vm._s(option.registrationNumber)+") ")])}},{key:"option",fn:function(ref){
var registrationNumber = ref.registrationNumber;
var sideNumber = ref.sideNumber;
return [_c('span',[_vm._v(_vm._s(sideNumber)+" ("+_vm._s(registrationNumber)+")")])]}}],null,true),model:{value:(_vm.myTeam.vehicle),callback:function ($$v) {_vm.$set(_vm.myTeam, "vehicle", $$v)},expression:"myTeam.vehicle"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(_vm._s(_vm.$t('popups.user.init-team.select-vehicle-no-result')))])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('div',[_c('validation-provider',{attrs:{"name":_vm.$t('popups.user.init-team.paramedic'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"paramedic"}},[_vm._v(" "+_vm._s(_vm.$t('popups.user.init-team.paramedic'))+" ")]),_c('b-form-group',[_c('v-select',{attrs:{"id":"paramedic","placeholder":_vm.$t('popups.user.init-team.select-paramedic'),"options":_vm.paramedics,"clearable":false,"searchable":false,"reduce":function (name) { return name.id; },"disabled":!_vm.me.roles.includes('ROLE_ADVANCED_DISPATCHER') || _vm.loading},scopedSlots:_vm._u([{key:"selected-option",fn:function(option){return _c('span',{},[_vm._v(" "+_vm._s(option.surname)+" "+_vm._s(option.name)+" ")])}},{key:"option",fn:function(ref){
var name = ref.name;
var surname = ref.surname;
return [_c('span',[_vm._v(" "+_vm._s(surname)+" "+_vm._s(name))])]}}],null,true),model:{value:(_vm.myTeam.paramedicUser),callback:function ($$v) {_vm.$set(_vm.myTeam, "paramedicUser", $$v)},expression:"myTeam.paramedicUser"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(_vm._s(_vm.$t('popups.user.init-team.select-paramedic-no-result')))])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('div',[_c('validation-provider',{attrs:{"name":_vm.$t('popups.user.init-team.driver'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"driver"}},[_vm._v(" "+_vm._s(_vm.$t('popups.user.init-team.driver'))+" ")]),_c('b-form-group',[_c('v-select',{attrs:{"id":"driver","placeholder":_vm.$t('popups.user.init-team.select-driver'),"options":_vm.drivers,"disabled":_vm.loading,"clearable":false,"searchable":false,"reduce":function (name) { return name.id; }},scopedSlots:_vm._u([{key:"selected-option",fn:function(option){return _c('span',{},[_vm._v(" "+_vm._s(option.surname)+" "+_vm._s(option.name)+" ")])}},{key:"option",fn:function(ref){
var name = ref.name;
var surname = ref.surname;
return [_c('span',[_vm._v(_vm._s(surname)+" "+_vm._s(name))])]}}],null,true),model:{value:(_vm.myTeam.driverUser),callback:function ($$v) {_vm.$set(_vm.myTeam, "driverUser", $$v)},expression:"myTeam.driverUser"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(_vm._s(_vm.$t('popups.user.init-team.select-driver-no-result')))])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)])])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }