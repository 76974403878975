<template>
  <b-nav-item-dropdown
    v-visibility-change="visibilityChange"
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="unreadCount"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          {{ $t('notifications.notifications') }}
        </h4>
        <b-badge
          v-if="unreadCount"
          pill
          variant="light-primary"
        >
          {{ unreadCount }}
          <span v-if="unreadCount > 4">
            {{ $t('notifications.notifications') }}
          </span>
          <span v-else-if="unreadCount > 1">
            {{ $t('notifications.notifications-lt-5') }}
          </span>
          <span v-else>
            {{ $t('notifications.notification') }}
          </span>
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!-- Account Notification -->
      <notification-item
        v-for="notification in notifications"
        :key="notification.id"
        :notification="notification"
      />

      <!-- System Notification Toggler -->
      <!--
      <div class="media d-flex align-items-center">
        <h6 class="font-weight-bolder mr-auto mb-0">
          System Notifications
        </h6>
        <b-form-checkbox
          :checked="true"
          switch
        />
      </div>
      -->

      <!-- System Notifications -->
      <!--
      <b-link
        v-for="notification in systemNotifications"
        :key="notification.subtitle"
      >
        <b-media>
          <template #aside>
            <b-avatar
              size="32"
              :variant="notification.type"
            >
              <feather-icon :icon="notification.icon" />
            </b-avatar>
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.title }}
            </span>
          </p>
          <small class="notification-text">{{ notification.subtitle }}</small>
        </b-media>
      </b-link>
      -->
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li
      v-if="unreadCount"
      class="dropdown-menu-footer"
    >
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        @click="markAllAsRead"
      >
        {{ $t('notifications.mark-all-read') }}
      </b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import PusherClient from '@/libs/pusher'
import BeamsClient from '@/libs/beams'
import {
  BBadge, BButton, BNavItemDropdown,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import NotificationItem from '@core/layouts/components/app-navbar/components/NotificationItem.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Favico from 'favico.js'

export default {
  components: {
    NotificationItem,
    BNavItemDropdown,
    BBadge,
    VuePerfectScrollbar,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      favicon: new Favico({
        type: 'rectangle',
        animation: 'popFade',
      }),
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
    }
  },
  computed: {
    me() {
      return this.$store.getters['auth/getUser']
    },
    notifications() {
      return this.$store.getters['auth/getNotifications']
    },
    config() {
      return this.$store.state.appConfig.organization.config
    },
    unreadCount() {
      return this.notifications.filter((notification => notification.unreaded)).length
    },
  },
  watch: {
    unreadCount(cnt) {
      this.favicon.badge(cnt)
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.bindNotification('all')
      this.bindNotification(`user-${this.me.username}`)
      this.me.rawRoles.forEach(role => {
        const rolesToBind = ['ROLE_DISPATCHER', 'ROLE_PARAMEDIC']
        if (rolesToBind.includes(role)) {
          this.bindNotification(`user-role-${role}`)
        }
      })
    },
    bindNotification(eventName) {
      PusherClient.bind('notifications', eventName, data => {
        this.$store.dispatch('auth/fetchNotifications')
        if (data.priority > 0) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('notifications.new'),
              icon: 'BellIcon',
              text: this.$t(`notifications.${data.type}.notify-title`),
              variant: 'primary',
            },
          })
        }
      })

      BeamsClient.addBeamsDeviceInterest(eventName)
    },
    markAllAsRead() {
      this.notifications.forEach(notification => {
        if (notification.unreaded) {
          this.$store.commit('auth/MARK_NOTIFICATION_AS_READ', notification.id)
          this.$store.dispatch('auth/markNotificationAsRead', notification.id)
        }
      })
    },
    visibilityChange(evt, hidden) {
      if (hidden) return
      this.$store.dispatch('auth/fetchNotifications')
    },
  },
}
</script>
