<template>
  <div>
    <b-modal
      id="modal-team-init"
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      :title="$t('popups.user.init-team.title')"
    >
      <div class="vx-row">
        <div class="vx-col w-full">

          <validation-observer ref="initTeamValidation">
            <form
              id="initTeamForm"
              @submit.prevent
            >
              <!--              <div class="vx-row mb-2">-->
              <!--                <div class="vx-col w-full">-->

              <div>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('popups.user.init-team.team-name')"
                  rules="required"
                >
                  <label for="team-name">
                    {{ $t('popups.user.init-team.team-name') }}
                  </label>
                  <b-form-group>
                    <v-select
                      id="team-name"
                      v-model="myTeam.teamName"
                      :placeholder="$t('popups.user.init-team.select-team-name')"
                      :options="teamNames"
                      :disabled="loading"
                      :clearable="false"
                      :searchable="false"
                      label="name"
                      :reduce="name => name.id"
                    >
                      <span slot="no-options">{{ $t('popups.user.init-team.select-team-name-no-result') }}</span>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </div>

              <div>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('popups.user.init-team.vehicle')"
                  rules="required"
                >
                  <label for="vehicle">
                    {{ $t('popups.user.init-team.vehicle') }}
                  </label>
                  <b-form-group>
                    <v-select
                      id="vehicle"
                      v-model="myTeam.vehicle"
                      :placeholder="$t('popups.user.init-team.select-vehicle')"
                      :options="vehicles"
                      :disabled="loading"
                      :clearable="false"
                      :searchable="false"
                      :reduce="name => name.id"
                    >
                      <span
                        slot="selected-option"
                        slot-scope="option"
                      >
                        {{ option.sideNumber }} ({{ option.registrationNumber }})
                      </span>
                      <template #option="{ registrationNumber, sideNumber }">
                        <span>{{ sideNumber }} ({{ registrationNumber }})</span>
                      </template>
                      <span slot="no-options">{{ $t('popups.user.init-team.select-vehicle-no-result') }}</span>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </div>

              <div>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('popups.user.init-team.paramedic')"
                  rules="required"
                >
                  <label for="paramedic">
                    {{ $t('popups.user.init-team.paramedic') }}
                  </label>
                  <b-form-group>
                    <v-select
                      id="paramedic"
                      v-model="myTeam.paramedicUser"
                      :placeholder="$t('popups.user.init-team.select-paramedic')"
                      :options="paramedics"
                      :clearable="false"
                      :searchable="false"
                      :reduce="name => name.id"
                      :disabled="!me.roles.includes('ROLE_ADVANCED_DISPATCHER') || loading"
                    >
                      <span
                        slot="selected-option"
                        slot-scope="option"
                      >
                        {{ option.surname }} {{ option.name }}
                      </span>
                      <template #option="{ name, surname }">
                        <span> {{ surname }} {{ name }}</span>
                      </template>
                      <span slot="no-options">{{ $t('popups.user.init-team.select-paramedic-no-result') }}</span>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </div>

              <div>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('popups.user.init-team.driver')"
                  rules="required"
                >
                  <label for="driver">
                    {{ $t('popups.user.init-team.driver') }}
                  </label>
                  <b-form-group>
                    <v-select
                      id="driver"
                      v-model="myTeam.driverUser"
                      :placeholder="$t('popups.user.init-team.select-driver')"
                      :options="drivers"
                      :disabled="loading"
                      :clearable="false"
                      :searchable="false"
                      :reduce="name => name.id"
                    >
                      <span
                        slot="selected-option"
                        slot-scope="option"
                      >
                        {{ option.surname }} {{ option.name }}
                      </span>
                      <template #option="{ name, surname }">
                        <span>{{ surname }} {{ name }}</span>
                      </template>
                      <span slot="no-options">{{ $t('popups.user.init-team.select-driver-no-result') }}</span>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </div>
            </form>
          </validation-observer>
        </div>
      </div>

      <template #modal-footer>
        <div class="w-100 text-right">
          <b-button
            v-if="forceShow"
            :disabled="loading"
            variant="flat"
            @click="cancel"
          >
            {{ $t('shared.cancel') }}
          </b-button>
          <b-button
            v-if="!forceShow"
            :disabled="loading"
            variant="flat"
            @click="logout"
          >
            {{ $t('shared.logout') }}
          </b-button>
          <b-button
            variant="primary"
            :disabled="loading"
            @click.prevent="submitForm"
          >
            {{ $t('popups.user.init-team.button') }}
          </b-button>
        </div>
      </template>

    </b-modal>
  </div>
</template>

<script>
import { cloneNested, parseRequestError } from '@/helpers/helpers'
import { BButton, BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BButton,
    vSelect,
  },
  data() {
    return {
      loading: true,
      myTeam: {
        status: true,
        teamName: '',
        vehicle: '',
        driverUser: '',
        paramedicUser: '',
      },
    }
  },
  computed: {
    me() {
      return cloneNested(this.$store.getters['auth/getUser'])
    },
    currentTeam() {
      return this.$store.getters['auth/getUserTeam']
    },
    vehicles() {
      return this.$store.getters['vehicle/getFreeVehicles']
    },
    teamNames() {
      return this.$store.getters['settings/getFreeTeamNames']
    },
    drivers() {
      return this.$store.getters['user/getDrivers']
    },
    paramedics() {
      return this.$store.getters['user/getParamedics']
    },
    forceShow() {
      return this.$store.getters['auth/getShowInitTeamPopup']
    },
    show() {
      return this.forceShow || (this.me.requirePasswordChange === false && this.me.teamLoginOnly === true && !this.currentTeam.id)
    },
  },
  mounted() {
    if (this.me.teamLoginOnly || this.forceShow) {
      this.$bvModal.show('modal-team-init')
      this.init(this.show)
    }
  },
  created() {
  },
  methods: {
    init(initialize) {
      if (!initialize) {
        return
      }

      Promise.all([
        this.$store.dispatch('vehicle/fetchFreeVehicles'),
        this.$store.dispatch('settings/fetchFreeTeamNames'),
        this.$store.dispatch('user/fetchDrivers'),
        this.$store.dispatch('user/fetchParamedics'),
      ])
        .then(() => {
          if (!this.me.roles.includes('ROLE_ADVANCED_DISPATCHER')) {
            this.paramedics.forEach(paramedic => {
              if (paramedic.id === this.me.id) {
                this.myTeam.paramedicUser = this.me.id
              }
            })
          }
          this.loading = false
        })
    },
    submitForm() {
      this.loading = true
      this.$refs.initTeamValidation.validate()
        .then(valid => {
          if (!valid) {
            return false
          }
          this.$store.dispatch('team/addTeam', this.myTeam)
            .then(() => {
              window.toast.notify.success(this.$t('popups.user.init-team.notify.success'))
              this.$store.dispatch('auth/fetchUserTeam')
              this.$store.dispatch('auth/setShowInitTeamPopup', false)
              this.clearForm()
            })
            .catch(err => parseRequestError(err))
            .finally(() => {
              this.loading = false
            })

          return true
        })
    },
    cancel() {
      this.$store.dispatch('auth/setShowInitTeamPopup', false)
    },
    logout() {
      this.$store.dispatch('auth/logout')
        .then(() => this.$router.push('/login'))
    },
    clearForm() {
      this.myTeam = {
        status: true,
        teamName: '',
        vehicle: '',
        driverUser: '',
        paramedicUser: '',
      }
    },
  },
}
</script>
