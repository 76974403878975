<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-lg-flex">

      <b-badge
        v-if="hasAtLeastOneRole(me.roles, ['ROLE_SUPER_ADMIN'])"
        variant="info"
        class="font-medium-3 p-50 text-truncate mx-1"
      >
        {{ companyName }}
      </b-badge>

      <team-accessibility-switcher />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <notification-dropdown />
      <dark-Toggler />
      <search-bar />
      <user-profile-dropdown />
    </b-navbar-nav>

    <password-change-popup v-if="requirePasswordChange" />
    <init-team-popup v-if="(isTeamLoaded && teamLoginOnly && !myTeam.id && !requirePasswordChange) || forceShowInitTeam" />

  </div>
</template>

<script>
import { BBadge, BLink, BNavbarNav } from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import UserProfileDropdown from '@/layouts/components/UserProfileDropdown.vue'
import SearchBar from '@core/layouts/components/app-navbar/components/SearchBar.vue'
import NotificationDropdown from '@core/layouts/components/app-navbar/components/NotificationDropdown.vue'
import TeamAccessibilitySwitcher from '@/layouts/components/TeamAccessibilitySwitcher.vue'
import InitTeamPopup from '@/layouts/components/popups/InitTeamPopup.vue'
import PasswordChangePopup from '@/layouts/components/popups/PasswordChangePopup.vue'
import guard from '@/guard'

export default {
  components: {
    TeamAccessibilitySwitcher,
    SearchBar,
    UserProfileDropdown,
    NotificationDropdown,
    BLink,
    BNavbarNav,
    BBadge,
    // Navbar Components
    DarkToggler,
    PasswordChangePopup,
    InitTeamPopup,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    companyName() {
      return this.$store.state.appConfig.organization.info.shortName
    },
    me() {
      return this.$store.state.auth.activeUser
    },
    myTeam() {
      return this.$store.state.auth.userTeam
    },
    isTeamLoaded() {
      return this.$store.state.auth.teamLoaded
    },
    requirePasswordChange() {
      return this.me.requirePasswordChange
    },
    teamLoginOnly() {
      return this.me.teamLoginOnly
    },
    forceShowInitTeam() {
      return this.$store.state.auth.showInitTeamPopup
    },
  },
  methods: {
    hasAtLeastOneRole: guard.hasAtLeastOneRole,
  },
}
</script>
