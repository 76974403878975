<template>
  <div :class="{'text-muted': notification.unreaded === false}">
    <b-link
      v-if="notification.type === 'order_new_comment'"
      @click="markAsRead(notification); `/order/view/${notification.data.id}` !== $router.currentRoute.path ? $router.push(`/order/view/${notification.data.id}`) : '';"
    >
      <b-media>
        <template #aside>
          <b-avatar
            size="32"
            variant="primary"
          >
            <feather-icon icon="MessageSquareIcon" />
          </b-avatar>
        </template>
        <p class="media-heading">
          <span class="font-weight-bolder">
            {{ sprintf($t(`notifications.${notification.type}.title`), notification.data.orderNumber) }}
          </span>
        </p>
        <p class="notification-text mb-0">
          <b-button
            v-show="notification.unreaded"
            variant="flat-success"
            class="float-right"
            @click.stop="markAsRead(notification)"
          >
            <feather-icon icon="CheckIcon" />
          </b-button>
          {{ $t(`notifications.${notification.type}.text`) }}
        </p>
        <p class="notification-text text-muted">
          {{ elapsedTime(notification.createdAt) }}
        </p>
      </b-media>
    </b-link>
    <b-link
      v-if="notification.type === 'system_new_version'"
      :class="{'opacity-50': notification.unreaded === false}"
      @click="markAsRead(notification); reload()"
    >
      <b-media>
        <template #aside>
          <b-avatar
            size="32"
            variant="danger"
          >
            <feather-icon icon="AlertCircleIcon" />
          </b-avatar>
        </template>
        <p class="media-heading">
          <span class="font-weight-bolder">
            {{ sprintf($t(`notifications.${notification.type}.title`), notification.data.orderNumber) }}
          </span>
          <small class="whitespace-no-wrap float-right">
            {{ elapsedTime(notification.createdAt) }}
          </small>
        </p>
        <p class="notification-text mb-0">
          {{ $t(`notifications.${notification.type}.text`) }}
        </p>
        <p class="notification-text text-muted">
          {{ elapsedTime(notification.createdAt) }}
        </p>
      </b-media>
    </b-link>
    <b-link
      v-if="notification.type === 'team_assigned_to_order'"
      :class="{'opacity-50': notification.unreaded === false}"
      @click="markAsRead(notification); `/order/view/${notification.data.id}` !== $router.currentRoute.path ? $router.push(`/order/view/${notification.data.id}`) : null;"
    >
      <b-media>
        <template #aside>
          <b-avatar
            size="32"
            variant="danger"
          >
            <feather-icon icon="FileTextIcon" />
          </b-avatar>
        </template>
        <p class="media-heading">
          <span class="font-weight-bolder">
            {{ sprintf($t(`notifications.${notification.type}.title`), notification.data.orderNumber) }}
          </span>
        </p>
        <p class="notification-text mb-0">
          <b-button
            v-show="notification.unreaded"
            variant="flat-success"
            class="float-right"
            @click.stop="markAsRead(notification)"
          >
            <feather-icon icon="CheckIcon" />
          </b-button>
          {{ $t(`notifications.${notification.type}.text`) }}
        </p>
        <p class="notification-text text-muted">
          {{ elapsedTime(notification.createdAt) }}
        </p>
      </b-media>
    </b-link>
    <b-link
      v-if="notification.type === 'team_is_free'"
      :class="{'opacity-50': notification.unreaded === false}"
      @click="markAsRead(notification); `/order/list` !== $router.currentRoute.path ? $router.push(`/order/list`) : '';"
    >
      <b-media>
        <template #aside>
          <b-avatar
            size="32"
            variant="danger"
          >
            <feather-icon icon="UsersIcon" />
          </b-avatar>
        </template>
        <p class="media-heading">
          <span class="font-weight-bolder">
            {{ sprintf($t(`notifications.${notification.type}.title`), notification.data.teamName) }}
          </span>
        </p>
        <p class="notification-text mb-0">
          <b-button
            v-show="notification.unreaded"
            variant="flat-success"
            class="float-right"
            @click.stop="markAsRead(notification)"
          >
            <feather-icon icon="CheckIcon" />
          </b-button>
          {{ $t(`notifications.${notification.type}.text`) }}
        </p>
        <p class="notification-text text-muted">
          {{ elapsedTime(notification.createdAt) }}
        </p>
      </b-media>
    </b-link>
  </div>
</template>
<script>
import {
  BLink, BMedia, BAvatar, BButton,
} from 'bootstrap-vue'

import { sprintf } from 'printj'
import BeamsClient from '@/libs/beams'

export default {
  name: 'NotificationItem',
  components: {
    BLink, BMedia, BAvatar, BButton,
  },
  props: {
    notification: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    sprintf,
    elapsedTime(startTime) {
      const x = new Date(startTime)
      const now = new Date()
      let timeDiff = now - x
      timeDiff /= 1000

      const seconds = Math.round(timeDiff)
      timeDiff = Math.floor(timeDiff / 60)

      const minutes = Math.round(timeDiff % 60)
      timeDiff = Math.floor(timeDiff / 60)

      const hours = Math.round(timeDiff % 24)
      timeDiff = Math.floor(timeDiff / 24)

      const days = Math.round(timeDiff % 365)
      timeDiff = Math.floor(timeDiff / 365)

      const years = timeDiff

      if (this.lastOne(years) === 1) {
        return `${years} ${this.$t('elapsed-time.year')}`
      }
      if (this.lastOne(years) > 4) {
        return `${years} ${this.$t('elapsed-time.years')}`
      }
      if (this.lastOne(years) > 0) {
        return `${years} ${this.$t('elapsed-time.years-lt-5')}`
      }

      if (this.lastOne(days) === 1) {
        return `${days} ${this.$t('elapsed-time.day')}`
      }
      if (this.lastOne(days) > 4) {
        return `${days} ${this.$t('elapsed-time.days')}`
      }
      if (this.lastOne(days) > 0) {
        return `${days} ${this.$t('elapsed-time.days-lt-5')}`
      }

      if (this.lastOne(hours) === 1) {
        return `${hours} ${this.$t('elapsed-time.hour')}`
      }
      if (this.lastOne(hours) > 4) {
        return `${hours} ${this.$t('elapsed-time.hours')}`
      }
      if (this.lastOne(hours) > 0) {
        return `${hours} ${this.$t('elapsed-time.hours-lt-5')}`
      }

      if (this.lastOne(minutes) === 1) {
        return `${minutes} ${this.$t('elapsed-time.minute')}`
      }
      if (this.lastOne(minutes) > 4) {
        return `${minutes} ${this.$t('elapsed-time.minutes')}`
      }
      if (this.lastOne(minutes) > 0) {
        return `${minutes} ${this.$t('elapsed-time.minutes-lt-5')}`
      }

      if (this.lastOne(seconds) === 1) {
        return `${seconds} ${this.$t('elapsed-time.second')}`
      }
      if (this.lastOne(seconds) > 4) {
        return `${seconds} ${this.$t('elapsed-time.seconds')}`
      }
      if (this.lastOne(seconds) > 0) {
        return `${seconds} ${this.$t('elapsed-time.seconds-lt-5')}`
      }

      return this.$t('elapsed-time.now')
    },
    lastOne(number) {
      return parseInt(number.toString().split('').pop(), 10)
    },
    markAsRead(notification) {
      if (!notification.unreaded) {
        return
      }
      this.$store.commit('auth/MARK_NOTIFICATION_AS_READ', notification.id)
      this.$store.dispatch('auth/markNotificationAsRead', notification.id)
    },
    reload() {
      BeamsClient.clearBeamsDeviceInterests()
      BeamsClient.stopBeams()
      caches.keys()
        .then(names => {
          for (let i = 0; i < names.length; i += 1) {
            const name = names[i]
            caches.delete(name)
          }
        })
        .then(() => {
          navigator.serviceWorker.getRegistrations()
            .then(keys => {
              Promise.all(
                keys.map(el => el.unregister()),
              )
            })
        })

      setTimeout(() => {
        window.location.reload(true)
      }, 250)
    },
  },
}
</script>
