<template>
  <div>
    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ me.name }} {{ me.surname }}
            </p>
            <span class="user-status">{{ me.position }}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            class="badge-minimal"
            badge-variant="success"
          >
            {{ me.name.charAt(0) }}{{ me.surname.charAt(0) }}
          </b-avatar>
        </template>

        <b-dropdown-item
          v-if="!activeUserTeam.id"
          v-visible-to-role="['ROLE_PARAMEDIC']"
          link-class="d-flex align-items-center"
          @click="showInitTeamPopup"
        >
          <feather-icon
            size="16"
            icon="UserCheckIcon"
            class="mr-50"
          />
          <span>{{ $t('login.start-team-work') }}</span>
        </b-dropdown-item>

        <b-dropdown-item
          v-if="activeUserTeam.id"
          link-class="d-flex align-items-center"
          @click="confirmTeamWorkEnd"
        >
          <feather-icon
            size="16"
            icon="UserXIcon"
            class="mr-50"
          />
          <span>{{ $t('login.end-team-work') }}</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logoutCheck"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>{{ $t('shared.logout') }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>
<script>
import {
  BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import { empty } from '@/helpers/helpers'

export default {
  components: {
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  computed: {
    me() {
      return this.$store.state.auth.activeUser
    },
    activeUserTeam() {
      return this.$store.state.auth.userTeam
    },
  },
  methods: {
    logoutCheck() {
      if (this.activeUserTeam.id) {
        this.$bvModal
          .msgBoxConfirm(
            this.$t('popups.user.init-team.end-team-work-during-logout.question'),
            {
              title: this.$t('popups.user.init-team.end-team-work-during-logout.title'),
              okTitle: this.$t('shared.yes'),
              cancelTitle: this.$t('shared.no'),
              okVariant: 'danger',
              cancelVariant: 'outline-secondary',
            },
          )
          .then(answer => {
            if (answer) {
              if (!empty(this.activeUserTeam.currentOrder)) {
                window.toast.notify.danger(this.$t('popups.user.init-team.notify.team-work-failed'))
                return
              }
              this.endTeamWork(true)
            } else { this.logout() }
          })
      } else {
        this.logout()
      }
    },
    logout() {
      this.$router.push('/logout')
    },
    showInitTeamPopup() {
      this.$store.dispatch('auth/setShowInitTeamPopup', true)
    },
    confirmTeamWorkEnd() {
      if (!empty(this.activeUserTeam.currentOrder)) {
        window.toast.notify.danger(this.$t('popups.user.init-team.notify.team-work-failed'))
        return
      }

      this.$bvModal
        .msgBoxConfirm(
          this.$t('popups.user.init-team.confirm-work-end.question'),
          {
            title: this.$t('popups.user.init-team.confirm-work-end.title'),
            okTitle: this.$t('shared.yes'),
            cancelTitle: this.$t('shared.no'),
            okVariant: 'danger',
            cancelVariant: 'outline-secondary',
          },
        )
        .then(answer => {
          if (answer) {
            this.endTeamWork(false)
          }
        })
    },
    endTeamWork(logout = false) {
      this.$store.dispatch('team/finishTeamWork', this.activeUserTeam.id).then(() => {
        window.toast.notify.success(this.$t('popups.user.init-team.notify.team-work-ended'))
        if (logout) {
          this.logout()
        }
        this.$store.dispatch('auth/clearUserTeam')
      })
    },
    empty,
  },
}
</script>
