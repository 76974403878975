import guard from '@/guard'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    me() {
      return this.$store.state.auth.activeUser
    },
  },
  methods: {
    hasAtLeastOneRole: guard.hasAtLeastOneRole,
  },
  render(h) {
    if (!this.hasAtLeastOneRole(this.me.roles, this.item.roles)) return h()
    const span = h('span', {}, this.$t(this.item.header))
    const icon = h('feather-icon', { props: { icon: 'MoreHorizontalIcon', size: '18' } })
    return h('li', { class: 'navigation-header text-truncate' }, [span, icon])
  },
}
