<template>
  <div
    v-if="!empty(myTeam.currentOrder)"
    class="mx-2 my-1"
  >
    <b-button
      variant="outline-primary"
      class="text-nowrap"
      pill
      block
      @click="goToCurrentOrder"
    >
      <feather-icon
        icon="FileTextIcon"
        class="mr-50"
      />
      {{ $t('team.current-order-button') }}
    </b-button>
  </div>
</template>
<script>
import { BButton } from 'bootstrap-vue'
import PusherClient from '@/libs/pusher'
import { empty, parseRequestError } from '@/helpers/helpers'

export default {
  components: {
    BButton,
  },
  data() {
    return {
      boundTeam: null,
    }
  },
  computed: {
    myTeam() {
      return this.$store.getters['auth/getUserTeam']
    },
  },
  watch: {
    myTeam: {
      handler() {
        if (this.boundTeam === null) {
          PusherClient.bind('update', 'team', data => {
            if (parseInt(data.id, 10) === parseInt(this.myTeam.id, 10)) {
              this.$store.dispatch('auth/fetchUserTeam')
            }
          })
          this.boundTeam = this.myTeam.id
        }
      },
      deep: true,
    },
  },
  methods: {
    addNewOrder() {
      this.addingNewOrder = true
      this.$store.dispatch('order/addOrder', {})
        .then(res => this.$router.push(`/order/edit/${res.data.id}`))
        .catch(err => parseRequestError(err))
        .finally(() => {
          this.addingNewOrder = false
        })
    },
    goToCurrentOrder() {
      if (this.$router.currentRoute.path === this.getPath()) {
        return
      }

      this.$router.push(this.getPath())
    },
    getPath() {
      return `/order/view/${this.myTeam.currentOrder.id}`
    },
    empty,
  },
}
</script>
